import styled from "styled-components";
import { themeGet } from '@styled-system/theme-get';
import {device} from '../../../theme'

export const AboutContentWrap = styled.section `
    padding-bottom: 56px;
    ${device.small}{
        padding-bottom: 76px;
    }
    ${device.medium}{
        padding-bottom: 96px;
    }

    h1, h2, h3, h4 {
        margin-bottom: 26px;
    }

    p {
        margin-bottom: 24px;
    }
`;