import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import {AboutContentWrap} from './about-content-area.stc'

const AboutContentArea = (props) => {
    const aboutData = useStaticQuery(graphql `
        query AboutContentQuery {
            markdownRemark(frontmatter: {type: {eq: "about"}}) {
                html
            }
        }
    `)
    const {html} = aboutData.markdownRemark

    return (
        <AboutContentWrap>
            <Container>
                <Row>
                    <Col><Heading as="h3">I'm a Digital Designer from Denver</Heading></Col>
                </Row>
                <Row>
                    <Col dangerouslySetInnerHTML={{__html: html}} lg={7}></Col>
                    <Col lg={5}>
                        <img style={{borderRadius: 5}} src="/about-cosplay.jpg" />
                    </Col>
                </Row>
            </Container>
        </AboutContentWrap>
    )
}

export default AboutContentArea


  