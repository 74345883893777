import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import {useStaticQuery, graphql} from 'gatsby'
import Image from '../../../components/image'
import HeroArea from '../../layout/hero-area'
import {AboutBannerWrap} from './about-banner-area.stc'

const AboutBannerArea = () => {
    const data = useStaticQuery(graphql `
        query AboutBannerQuery {
            aboutJson(id: {eq: "about-hero-content"}){
                image {
                    childImageSharp {
                        fluid(maxWidth: 1920, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }      
    `);
    const heroImage = data.aboutJson.image;
    return (
        <AboutBannerWrap>
            <HeroArea image={heroImage} preheading="My name is" heading="Carina Wang"/>
        </AboutBannerWrap>
    )
}

export default AboutBannerArea
